<template>
    <section id="SettledBets">
        <h3 class="no-results" v-if="filteredBets.length === 0">No results available.</h3>
        <Bet v-for="bet in filteredBets" :key="bet.createdAt" :bet="bet" :wagers="wagers" :books="books" />
        <DateFilters v-if="filteredBets.length > 0" :selected="selectedFilter" @apply="applyFilter" @clear="clearFilter" />
        <Options :options="options" :selected="1" hideShadow />
    </section>
</template>

<script>

    // Components.
    import Options from '@/components/Options.vue';
    import Bet from '@/components/Bet.vue';
    import DateFilters from '@/components/DateFilters.vue';

    // Firebase.
    import { getBet, getWager } from '@/firebase/wagers.js';
    import { getBook } from '@/firebase/books.js';

    // Misc.
    import { BETS_OPTIONS } from '@/constants.js';

    const MONTHS = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    export default {
        name: 'SettledBets',
        components: {
            Options,
            Bet,
            DateFilters,
        },
        data() {
            return {
                bets: [],
                wagers: {},
                books: {},
                isFilterApplied: false,
                filterYear: null,
                filterMonth: null,
            }
        },
        computed: {
            options() {
                return BETS_OPTIONS;
            },
            filteredBets() {
                return this.bets.filter(bet => {

                    let isSettled = true;

                    for (const b of bet.options.bets) {
                        if (this.wagers[b.wager[0]].status !== 'settled') {
                            isSettled = false;
                            break;
                        }
                    }

                    // Date filter.
                    if (this.isFilterApplied) {

                        const date = new Date(bet.createdAt);

                        if (date.getFullYear() !== this.filterYear || date.getMonth() !== this.filterMonth) {
                            isSettled = false;
                        }

                    }

                    return isSettled && !bet.void;

                }).sort((a, b) => b.createdAt - a.createdAt);
            },
            selectedFilter() {
                return (!this.isFilterApplied) ? 'ALL' : `${ MONTHS[this.filterMonth] } ${ this.filterYear }`;
            }
        },
        mounted() {
            this.$store.dispatch('setNavShadow', false);
        },
        created() {
            if (this.profile.bets) {

                for (let bet of Object.keys(this.profile.bets)) {

                    this.$store.dispatch('updateLoading', 1);

                    getBet(bet).then(async snapshot => {

                        const val = snapshot.val();

                        for (let b of val.options.bets) {
                            if (!this.wagers[b.wager[0]]) {
                                this.wagers[b.wager[0]] = (await getWager(b.wager[0])).val();
                            }
                        }

                        this.books[val.book] = (await getBook(val.book)).val();
                        this.bets.push(val);
                        this.$store.dispatch('updateLoading', -1);

                    });

                }
            }
        },
        methods: {
            applyFilter(date) {
                this.filterYear = parseInt(date.year);
                this.filterMonth = parseInt(date.month);
                this.isFilterApplied = true;
            },
            clearFilter() {
                this.filterYear = null;
                this.filterMonth = null;
                this.isFilterApplied = false;
            }
        }
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    #SettledBets {
        padding: size(Medium) 0 50px + size(Medium) + size(Large);
    }

    .no-results {
        padding: 0 size(Medium);
        text-align: center;
    }

    .Bet:first-of-type {
        margin-top: 0;
    }

    .DateFilters {
        position: fixed !important;
        bottom: 50px;
        left: 0;
        z-index: 10;
    }

</style>
