<template>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" class="Chevron" :class="theme">
        <g>
            <path d="M13.8291 3.37921C13.6013 3.15141 13.232 3.15141 13.0042 3.37921L6.99998 9.38338L0.995781 3.37921C0.76798 3.15141 0.398621 3.15141 0.17082 3.37921C-0.0569807 3.60701 -0.0569807 3.97637 0.17082 4.20417L6.5875 10.6208C6.8153 10.8486 7.18466 10.8486 7.41246 10.6208L13.8291 4.20417C14.0569 3.97634 14.0569 3.60701 13.8291 3.37921Z" />
        </g>
    </svg>
</template>

<script>

    export default {
        name: 'Chevron',
        props: {
            theme: { type: String, default: 'white', validator: value => {
                return ['white', 'grey'].indexOf(value) !== -1;
            }},
        }
    }

</script>

<style lang="scss" scoped>

    @import '../../styles/_variables.scss';

    .white path, .white rect {
        fill: color(White);
    }

    .grey path, .grey rect {
        fill: color(Emperor);
    }

</style>
