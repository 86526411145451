<template>
    <div class="DateFilters">
        <div class="bar" @click="toggle">
            <h4>Filter By</h4>
            <h4 class="selected">{{ selected }}</h4>
        </div>
        <transition
            v-on:enter="enter"
            v-on:leave="leave"
        >
            <div class="panel" v-if="isOpen">
                <div class="header">
                    <h4>Filter By</h4>
                    <Close @onClick="toggle" />
                </div>
                <div class="filters">
                    <form @submit="apply">
                        <div class="dropdown">
                            <select v-model="year">
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                            </select>
                            <Chevron theme="grey" />
                        </div>
                        <div class="dropdown">
                            <select v-model="month">
                                <option value="0">January</option>
                                <option value="1">February</option>
                                <option value="2">March</option>
                                <option value="3">April</option>
                                <option value="4">May</option>
                                <option value="5">June</option>
                                <option value="6">July</option>
                                <option value="7">August</option>
                                <option value="8">September</option>
                                <option value="9">October</option>
                                <option value="10">November</option>
                                <option value="11">December</option>
                            </select>
                            <Chevron theme="grey" />
                        </div>
                        <Button arrow>Apply</Button>
                    </form>
                    <Button theme="red" @onClick="clear">Clear</Button>
                </div>
            </div>
        </transition>

    </div>
</template>

<script>

    // Modules.
    import gsap from 'gsap';

    // Components.
    import Close from '@/components/Close.vue';
    import Button from '@/components/Button.vue';
    import Chevron from '@/components/vectors/Chevron.vue';

    export default {
        name: 'DateFilters',
        components: {
            Close,
            Button,
            Chevron,
        },
        data() {

            const now = new Date();

            return {
                isOpen: false,
                year: `${ now.getFullYear() }`,
                month: `${ now.getMonth() }`,
            };

        },
        props: {
            selected: { type: String, required: true },
        },
        methods: {
            toggle() {
                this.isOpen = !this.isOpen;
            },
            apply(e) {

                e.preventDefault();

                this.toggle();
                this.$emit('apply', { year: this.year, month: this.month });

            },
            clear() {
                this.toggle();
                this.$emit('clear');
            },
            enter(el, done) {
                const height = el.getBoundingClientRect().height + 15; // Account for box shadow.
                gsap.fromTo(el, { y: height }, { y: 0, duration: 0.75, ease: 'power3.inOut', onComplete: done });
            },
            leave(el, done) {
                const height = el.getBoundingClientRect().height + 15; // Account for box shadow.
                gsap.to(el, { y: height, duration: 0.75, ease: 'power3.inOut', onComplete: done });
            }
        }
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    .DateFilters {

        position: relative;
        width: 100%;

        background-color: color(White);
        box-shadow: 0 0 15px color(Black, 0.1);

    }

    .bar {

        padding: 0 size(Medium);
        height: size(Large);

        display: flex;
        align-items: center;
        justify-content: space-between;

    }

    h4 {
        text-transform: uppercase;
    }

    .bar .selected {
        opacity: 0.25;
    }

    .panel {

        position: absolute;
        bottom: 0;
        left: 0;

        width: 100%;
        padding: size(Medium) size(Medium) size(Small);

        background-color: color(White);
        box-shadow: 0 0 15px color(Black, 0.1);

    }

    .header {

        display: flex;
        align-items: center;
        justify-content: space-between;

        padding-bottom: size(Medium);

        border-bottom: 1px solid color(Emperor, 0.15);

    }

    .filters {
        padding: size(Small) size(Large);
    }

    .dropdown {
        position: relative;
    }

    .dropdown,
    .Button {
        margin-top: size(Small);
    }

    .Chevron {

        position: absolute;
        top: 50%;
        right: size(Medium);

        transform: translateY(-50%);

        pointer-events: none;

    }

</style>
